import React from "react"
import {
  ChildContainer,
  Description,
  ParentContainer,
  Title,
  Image,
  TitleSub,
  SubText,
  Ul,
  LearnMore,
  SvgHolder,
  TitleHolder,
} from "../../../ToolsStyles/UsefulToolsStyled"
import Folder from "../icons/folder.svg"

const UsefulTools = () => {
  return (
    <ParentContainer>
      <TitleHolder>
        <Title>Continue to organize this PDF</Title>
        <Description>
          Here are a couple other tools that you may want to use on your current
          PDF document.
        </Description>
        <LearnMore to="/pdf-online-tools/">
          View all Online tools &rarr;
        </LearnMore>
      </TitleHolder>

      <ChildContainer>
        <SvgHolder>
          <Folder />
        </SvgHolder>
        <TitleSub>PDF to Word </TitleSub>
        <SubText>Convert a PDF to word.</SubText>
        <LearnMore to="/pdf-to-word/">Continue &rarr;</LearnMore>
      </ChildContainer>

      <ChildContainer>
        <SvgHolder>
          <Folder />
        </SvgHolder>
        <TitleSub>Excel to PDF</TitleSub>
        <SubText>Convert a Excel to PDF.</SubText>
        <LearnMore to="/excel-to-pdf/">Continue &rarr;</LearnMore>
      </ChildContainer>
    </ParentContainer>
  )
}

export default UsefulTools
